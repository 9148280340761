import React from 'react';
import {Box} from '@rebass/emotion';

import Layout from '../components/layout';
import Button from '../components/Button';
import SEO from '../components/seo';
import Slices from '../modules/Slices';
import Heading from '../components/Heading';
import Text from '../components/Text';
import Wrap from '../components/Wrap';

const NotFoundPage = ({location}) => (
  <Layout location={location}>
    <SEO title="Citra. 404: Not found" />
    <Slices />
    <Wrap>
      <Box style={{zIndex: '1'}} py={['50%', '30%', '10%']} width={1}>
        <Heading textAlign="center" fontSize={['25vw']} color="white">404</Heading>
        <Text textAlign="center" color="white">We can't find this page. <Button to="/" light>Head Home</Button></Text>
      </Box>
    </Wrap>
  </Layout>
)

export default NotFoundPage
